<template>
    <button class="btn btn-sm btn-primary ml-2 d-flex" 
        :class="{ 'disabled': props.dataObject.selectionControl.selectedRows.length < 1}" 
        :title="props.btnTitle"
        @click="showModal"
        style="gap:3px;">
        <i class="bi bi-arrow-bar-up"></i><span>{{props.btnInnerHtml}}</span>
    </button>
    <OModal name="batchPublishToOrgUnitsModal" ref="batchPublishToOrgUnitsModal" @hidden="handleHidden">
        <div class="modal-dialog d-flex modal-lg">
            <div class="modal-content d-flex flex-column">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('Batch Publish To Org Unit(s)') }}</h5>
                    <button type="button" class="btn-close" @click="hideModal" aria-label="Close"></button>
                </div>
                <div class="modal-body d-flex flex-column" style="max-height:80vh; gap:20px;">
                    <div>
                        <h6 class="fw-light">{{$t('Selected item(s) to publish')}}</h6>
                        <div style="gap:3px;" class="d-flex flex-wrap">
                            <span v-for="(vItem, index) in props.dataObject.selectionControl.selectedRows" class="badge badgeStyle fs-6">
                                {{vItem[props.keyProperty]}}
                                <i class="bi bi-x" style="cursor:pointer;" @click="removeSelectedItem(index)"></i>
                            </span>
                        </div>
                    </div>
                    <div>
                        <div>     
                            <OOrgUnitsLookup
                                :bind="sel => {
                                        if (!SelectedOrgUnits.find(obj => obj.ID === sel.ID)) {
                                            SelectedOrgUnits.push({ID:sel.ID, Title:sel.OrgUnit, excludeSubUnits: 0});
                                        }
                                    }">
                                <template #orgunit>
                                    <span class="fw-light" :class="{ 'text-decoration-underline': orgUnitSelect_isHovered }" @mouseover="addUnderline" @mouseleave="removeUnderline" >{{$t('Org Unit(s): Select...')}}</span>
                                    <span style="cursor:pointer;">
                                        <i class="bi bi-arrow-down-short"></i>
                                    </span>
                                </template>
                            </OOrgUnitsLookup>
                        </div>
                        <div v-if="SelectedOrgUnits.length > 0">
                            <hr class="mb-0" style="margin-top: 5px;">
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item d-flex align-items-center">
                                    <div class="col-md-1 fw-light">
                                        {{$t('ID')}}
                                    </div>
                                    <div class="col-md-7 fw-light">
                                        {{$t('Org Unit')}}
                                    </div>
                                    <div class="col-md-3 text-center fw-light">
                                        {{$t('Exclude Sub Units')}}
                                    </div>
                                </li>

                                <li class="list-group-item d-flex align-items-center pb-0" v-for="(vOrgUnit,index) in SelectedOrgUnits">
                                    <div class="col-md-1">
                                        {{vOrgUnit.ID}}
                                    </div>
                                    <div class="col-md-7">
                                        {{vOrgUnit.Title}}
                                    </div>
                                    <div class="col-md-3 text-center">
                                    <input type="checkbox" class="form-check-input ms-2" style="cursor:pointer;" :value="vOrgUnit.excludeSubUnits ? 1 : 0" @input="updateExcludeSubUnits(index)">
                                    </div>
                                    <div class="col-md-1 text-center ps-5">
                                        <i class="bi bi-x fs-4 text-primary" style="cursor:pointer;" @click="removeSelectedOrgUnit(index)"></i>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" 
                        class="btn btn-primary btn-sm" 
                        @click="publishItems" 
                        :title="$t('Batch publish to org unit(s)')" 
                        :class="{ 'disabled': SelectedOrgUnits.length < 1}">
                            {{$t("Publish")}}
                        </button>
                    <button type="button" class="btn btn-outline-secondary btn-sm" @click="hideModal">{{$t("Close")}}</button>
                </div>
            </div>
        </div>
    </OModal>
</template>

<script setup>
    import { OOrgUnitsLookup } from "o365-system-lookups";
    import { ref, defineEmits, defineProps } from 'vue';
    import { alert } from 'o365-vue-services';

    const props = defineProps({
        dataObject: {
            type: Object,
            required: true
        },
        keyProperty: {
            type: String,
            required: true
        },
        btnInnerHtml: {
            type: String,
            default: $t('Batch Publish')
        },
        btnTitle: {
            type: String,
            default: $t('Publish selected item(s) to Org Unit(s)')
        }

    });

    const emit = defineEmits(['submitted']);
    const batchPublishToOrgUnitsModal = ref(null);
    const SelectedOrgUnits = ref([]);

    const showModal = () => {
        batchPublishToOrgUnitsModal.value?.show();
    }
    const hideModal = () => {
        batchPublishToOrgUnitsModal.value?.hide();
    }

    const publishItems = () => {
        if (SelectedOrgUnits.value.length < 1) {
            return alert($t("Please choose org unit(s) to publish to"), 'warning', { autohide: true, delay: 3000});
        }

        if (props.dataObject.selectionControl.selectedRows.length < 1) {
            return alert($t("No item(s) are selected to be published"), 'warning', { autohide: true, delay: 3000});
        }

        let vSelectedItems = props.dataObject.selectionControl.selectedRows.map(pItem => {
            return [pItem.ID];
        });

        let vOrgUnitIDs = SelectedOrgUnits.value.map(OrgUnit => {
            return [OrgUnit.ID, OrgUnit.excludeSubUnits];
        });

        emit('submitted', vSelectedItems, vOrgUnitIDs, hideModal)
    };

    const updateExcludeSubUnits = (index) => {
        SelectedOrgUnits.value[index].excludeSubUnits = !SelectedOrgUnits.value[index].excludeSubUnits ? 1 : 0;
    }

    const removeSelectedItem = (index) => {
        props.dataObject.selectionControl.selectedRows[index].state.isSelected = false

        if (props.dataObject.selectionControl.selectedRows.length < 1) {
            hideModal();
        }
    }

    const removeSelectedOrgUnit = (index) => {
        SelectedOrgUnits.value.splice(index, 1);
    }

    const handleHidden = (e) => { 
        SelectedOrgUnits.value.splice(0, SelectedOrgUnits.value.length);
    }

    const orgUnitSelect_isHovered = ref(false);


    const addUnderline = () => {
        orgUnitSelect_isHovered.value = true;
    }

    const removeUnderline = () => {
        orgUnitSelect_isHovered.value = false;
    }     

</script>
<style scoped>
    .badgeStyle {
        color: #4682B4;
        border: 1px solid #4682B4;
        background-color: transparent !important;
    }
</style>